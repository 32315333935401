import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NotFoundPage from './components/pages/NotFoundPage/NotFoundPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './components/Auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OutletInventories from './components/pages/TransferOfStock/OutletsSidebarPage/OutletInventories';
import TransferDetails from './components/pages/TransferOfStock/OutletsSidebarPage/TransferDetails';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Sidebar from './components/global/sidebar';
import Topbar from './components/global/topbar';
import OutletSidebar from './components/Outlet/OutletSidebar/OutletSidebar';
import OutletTopbar from './components/Outlet/OutletSidebar/OutletTopbar';
import history from './components/global/history';
import { jwtDecode } from "jwt-decode";

const SalesPage = lazy(() => import('./components/pages/Sales/SalesPage'));
const StockPage = lazy(() => import('./components/pages/Stock/StockPage'));
const TransferOfStockPage = lazy(() => import('./components/pages/TransferOfStock/TransferOfStockPage'));
const HrPage = lazy(() => import('./components/pages/HR/HrPage'));
const EnquiryPage = lazy(() => import('./components/pages/Enquiry/EnquiryPage'));
const Login = lazy(() => import('./components/Login/Login'));
const LogoutSuccessPage = lazy(() => import('./components/LogoutPage/LogoutSuccessPage'));
const Dashboard = lazy(() => import('./components/pages/Dashboard/dashboard'));
const Form = lazy(() => import('./components/pages/User/createUserForm'));
const Customer = lazy(() => import('./components/pages/Customer/Customer'));
const Trash = lazy(() => import('./components/pages/Stock/Trash'));
const OutletDashboard = lazy(() => import('./components/Outlet/OutletDashboard/OutletDashboard'));
const OutletStocks = lazy(() => import('./components/Outlet/OutletStocks/OutletStocks'));
const OutletSale = lazy(() => import('./components/Outlet/OutletSale/OutletSale'));
const OutletEmployeePage = lazy(() => import('./components/Outlet/OutletHr/OutletEmployeePage'));
const OutletEnquiryPage = lazy(() => import('./components/Outlet/OutletEnquiry/OutletEnquiryPage'));
const OutletCustomer = lazy(() => import('./components/Outlet/OutletCustomer/OutletCustomer'));
const CustomerOrder = lazy(() => import('./components/pages/CustomerOrder/CustomerOrder'));
const Exchange = lazy(() => import('./components/pages/Exchange/Exchange'))

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [theme, colorMode] = useMode();
  const [userRole, setUserRole] = useState(null);
  const INACTIVITY_TIMEOUT = 30 * 60 * 1000;
  let logoutTimeout;

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      history.push('/login');
    } else {
      setAuthenticated(true);
      const role = getUserRole(token);
      setUserRole(role);
    }
    startLogoutTimer();

    window.addEventListener('beforeunload', saveCurrentRoute);
    window.addEventListener('mousemove', resetLogoutTimer);
    window.addEventListener('keydown', resetLogoutTimer);
    window.addEventListener('click', resetLogoutTimer);

    return () => {
      window.removeEventListener('beforeunload', saveCurrentRoute);
      window.removeEventListener('mousemove', resetLogoutTimer);
      window.removeEventListener('keydown', resetLogoutTimer);
      window.removeEventListener('click', resetLogoutTimer);
      clearTimeout(logoutTimeout);
    };
  }, []);

  const saveCurrentRoute = () => {
    const currentRoute = window.location.pathname;
    localStorage.setItem('currentRoute', currentRoute);
  };

  const getUserRole = (token) => {
    const decoded = jwtDecode(token);
    const roles = decoded.roles;
    let role = null;
    if (roles.includes('ROLE_ADMIN')) {
      role = 'ADMIN';
    } else if (roles.includes('ROLE_MANAGER')) {
      role = 'MANAGER';
    } else if (roles.includes('ROLE_OUTLET')) {
      role = 'OUTLET';
    }
    else if (roles.includes('ROLE_OUTLETMANAGER')) {
      role = 'OUTLETMANAGER';
    }
    return role;
  };

  const startLogoutTimer = () => {
    logoutTimeout = setTimeout(handleLogout, INACTIVITY_TIMEOUT);
  };

  const resetLogoutTimer = () => {
    clearTimeout(logoutTimeout);
    startLogoutTimer();
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setAuthenticated(false);
    history.push('/login');
  };

  if (authenticated === null) {
    return <div>Loading...</div>;
  }

  const getStoredRoute = () => {
    return localStorage.getItem('currentRoute') || '/';
  };

  const getDefaultRoute = () => {
    switch (userRole) {
      case 'ADMIN':
        return '/dashboard';
      case 'MANAGER':
        return '/sales';
      case 'OUTLET':
        return '/outletDashboard';
      case 'OUTLETMANAGER':
        return '/outletDashboard';
      default:
        return '/not-found'; // Fallback route
    }
  };

  return (
    <AuthProvider>
      <Router history={history}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToastContainer />
            <Suspense fallback={<div>Loading...</div>}>
              {authenticated ? (
                <>
                  <div className="app">
                    {['ADMIN', 'MANAGER'].includes(userRole) ? (
                      <>
                        <Sidebar />
                        <Topbar />
                      </>
                    ) : (
                      <>
                        <OutletSidebar />
                        <OutletTopbar />
                      </>
                    )}
                    <main className="content">
                      <Routes>
                        <Route path="/" element={<Navigate to={getDefaultRoute()} />} />
                        <Route path="/sales" element={<SalesPage />} />
                        <Route path="/stock" element={<StockPage />} />
                        <Route path="/transfer-of-stocks" element={<TransferOfStockPage />} />
                        <Route path="/hr" element={<HrPage />} />
                        <Route path="/enquiry" element={<EnquiryPage />} />
                        <Route path="/not-found" element={<NotFoundPage />} />
                        <Route path="/logout" element={<LogoutSuccessPage />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/outlet_inventories" element={<OutletInventories />} />
                        <Route path="/transfer-details" element={<TransferDetails />} />
                        <Route path="/form" element={<Form />} />
                        <Route path="/outletStocks" element={<OutletStocks />} />
                        <Route path="/outletSales" element={<OutletSale />} />
                        <Route path="/customer" element={<Customer />} />
                        <Route path="/trash" element={<Trash />} />
                        <Route path="/outletDashboard" element={<OutletDashboard />} />
                        <Route path="/outletHr" element={<OutletEmployeePage />} />
                        <Route path="/outletCustomer" element={<OutletCustomer />} />
                        <Route path="/outletEnquiries" element={<OutletEnquiryPage />} />
                        <Route path="/outletCustomer" element={<OutletCustomer />} />
                        <Route path="/customer-orders" element={<CustomerOrder />} />
                        <Route path="/exchange" element={<Exchange />} />
                        <Route path="*" element={<Navigate to={getStoredRoute()} />} />
                      </Routes>
                    </main>
                  </div>
                </>
              ) : (
                <Routes>
                  <Route path="/" element={<Login setAuthenticated={setAuthenticated} />} />
                  <Route path="/logout" element={<LogoutSuccessPage />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              )}
            </Suspense>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </Router>
    </AuthProvider>
  );
}

export default App;
