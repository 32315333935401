import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import './sidebar.css';
import { jwtDecode } from 'jwt-decode';
import logo from '../Images/Logo/Logo.png';

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const getAccessToken = () => {
    const accessToken = localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    const roles = decoded.roles;
    const outletId = decoded.outletId;
    let role = null;
    let email = null;
    if (decoded.sub) {
        email = decoded.sub;
    }
    if (roles.includes('ROLE_ADMIN')) {
        role = 'ADMIN';
    } else if (roles.includes('ROLE_OUTLET')) {
        role = 'OUTLET';
    }
    return { accessToken, role, outletId, email };
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [selected, setSelected] = useState("Dashboard");
    const { email } = getAccessToken();
    const displayName = email ? `${email.split('@')[0].charAt(0).toUpperCase()}${email.split('@')[0].slice(1)}` : '';
    const [profileImage, setProfileImage] = useState(null);
    const [userId, setUserId] = useState([]);

    const getUserIdByEmail = async (email) => {
        try {
            const accessToken = getAccessToken().accessToken; // Access token value
            const response = await fetch(`https://d37sta01pkk8ye.cloudfront.net/api/v1/users/getId/${encodeURIComponent(email)}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Use the actual access token
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch user ID: ${response.statusText}`);
            }

            const data = await response.json();
            setUserId(data);
            console.log("Id: ", data);
        } catch (error) {
            console.error('Error fetching user ID:', error);
        }
    };

    const getUserProfileImage = async (userId) => {
        try {
            const accessToken = getAccessToken().accessToken;
            const response = await fetch(`https://d37sta01pkk8ye.cloudfront.net/api/v1/users/${userId}/profile-image`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const imageData = await response.blob();
                setProfileImage(URL.createObjectURL(imageData));
            }
        } catch (error) {
            console.error('Error fetching user profile image:', error);

        }
    };

    useEffect(() => {
        getUserIdByEmail(email);
    }, [email]);

    useEffect(() => {
        if (userId) {
            getUserProfileImage(userId);
        }
    }, [userId]);

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#2e7c67 !important",
                },
                "& .pro-menu-item.active": {
                    color: "#94e2cd !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Box display="flex" alignItems="center">
                                    <img src={logo} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                                </Box>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                {profileImage ? (
                                    <img
                                        alt="profile-user"
                                        width="100px"
                                        height="100px"
                                        src={profileImage}
                                        style={{ cursor: "pointer", borderRadius: "50%" }}
                                    />
                                ) : (
                                    <Typography variant="body1" color={colors.grey[100]}>
                                        Loading...
                                    </Typography>
                                )}
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {displayName}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    Admin
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Sales"
                            to="/sales"
                            icon={<AttachMoneyOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Orders"
                            to="/customer-orders"
                            icon={<AddShoppingCartIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Stocks"
                            to="/stock"
                            icon={<Inventory2OutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Exchange"
                            to="/exchange"
                            icon={<MoveDownIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="HR"
                            to="/hr"
                            icon={<BadgeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Customer Information"
                            to="/customer"
                            icon={<ContactsOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Enquiry"
                            to="/enquiry"
                            icon={<SupportAgentOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            Data
                        </Typography>
                        <Item
                            title="Manage Outlets"
                            to="/transfer-of-stocks"
                            icon={<AddBusinessOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Outlet Inventories"
                            to="/outlet_inventories"
                            icon={<Inventory2OutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Transfer Details"
                            to="/transfer-details"
                            icon={<InventoryOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            Pages
                        </Typography>
                        <Item
                            title="Create User"
                            to="/form"
                            icon={<PersonOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Calendar"
                            to="/calendar"
                            icon={<CalendarTodayOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Trash"
                            to="/trash"
                            icon={<DeleteOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="FAQ Page"
                            to="/faq"
                            icon={<HelpOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            Charts
                        </Typography>
                        <Item
                            title="Bar Chart"
                            to="/bar"
                            icon={<BarChartOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Pie Chart"
                            to="/pie"
                            icon={<PieChartOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Line Chart"
                            to="/line"
                            icon={<TimelineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Geography Chart"
                            to="/geography"
                            icon={<MapOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
